@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		opacity: 0.2;
	}

	50% {
		transform: scale(1);
		opacity: 0.4;
	}

	100% {
		transform: scale(0.95);
		opacity: 0.2;
	}
}
@keyframes slideUp {
	// 0%  {transform: translateY(100px); opacity:0;}
	// //75% {transform: translateY(75px); opacity:0.5;}
	// 100% {transform: translateY(0); opacity:1;}
	0% {
		opacity: 0;
		transform: translateY(40vh) skewY(7deg);
	}

	100% {
		opacity: 1;
		transform: translateY(0) skewY(0deg);
	}
}
@-webkit-keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(30%) skewY(-7deg);
	}

	100% {
		opacity: 1;
		transform: translateY(0) skewY(0deg);
	}
}
@keyframes slideRight {
	0% {
		transform: translateX(100px);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes text {
	0% {
		transform: translateY(10vh);
		line-height:8em;
		opacity:0;
	}

	100% {
		transform: translateY(0);
		line-height:2em;
		opacity:1;
	}
}
@keyframes fade-slide-down {
	0% {
		opacity: 0;
		//transform: translateY(-4rem);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}
@keyframes squiggle-circle {
	0%{
		opacity:1;
	}
	100% {
		stroke-dashoffset: 0;
		opacity:1;
	}
}

@keyframes OnybbKPy_draw{
	100%{
		stroke-dashoffset:0;
	}
}
@keyframes OnybbKPy_fade{
	0%{
		stroke-opacity:1;
	}
	94.44444444444444%{
		stroke-opacity:1;
	}
	100%{
		stroke-opacity:0;
	}
}

@keyframes strike{
  0%   {
	  left:0;
	  width : 0;
  }
  50%   {
	  left:0;
	  width : 100%;
  }
  100% {
	  width: 0;
	  left:100%;
  }
}