#intro-container {
  path {
    fill: none;
    stroke: #d61920;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
}

.inline {
  position: relative;
}

.inline svg {
  position: absolute;
}

#trans-line {
  width: 157px;
  bottom: -5px;
}

#trans-line-path {
  stroke-dasharray: 159;
  stroke-dashoffset: 159;
  animation: squiggle-circle 0.15s linear 0ms forwards;
  animation-delay: 0.5s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#firm-line {
  width: 45px;
  top: calc(50% - 3px);
}

#firm-line-path {
  stroke-dasharray: 47;
  stroke-dashoffset: 47;
  animation: squiggle-circle 0.15s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  animation-delay: 1.2s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-x {
  width: 115px;
  top: 5px;
  left: -5px;
}

#anti-x-1 {
  stroke-dasharray: 414;
  stroke-dashoffset: 414;
  animation: squiggle-circle 0.35s linear 0ms forwards;
  animation-delay: 1.75s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-x-2 {
  stroke-dasharray: 382;
  stroke-dashoffset: 382;
  animation: squiggle-circle 0.35s linear 0ms forwards;
  animation-delay: 2.1s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti {
  width: 50px;
  top: -42px;
  left: -23px;
}

#anti-arrow {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: squiggle-circle 0.5s ease-out 0ms forwards;
  animation-delay: 2.5s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-a {
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
  animation: squiggle-circle 0.25s linear 0ms forwards;
  animation-delay: 2.9s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-n {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: squiggle-circle 0.15s linear 0ms forwards;
  animation-delay: 3.25s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-t {
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  animation: squiggle-circle 0.05s linear 0ms forwards;
  animation-delay: 3.5s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-cross {
  stroke-dasharray: 10;
  stroke-dashoffset: 10;
  animation: squiggle-circle 0.05s linear 0ms forwards;
  animation-delay: 3.76s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 0;
}

#anti-i {
  stroke-dasharray: 90;
  stroke-dashoffset: 90;
  animation: squiggle-circle 0.05s linear 0ms forwards;
  animation-delay: 4.1s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#anti-dot {
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
  animation: squiggle-circle 0.15s linear 0ms forwards;
  animation-delay: 4.25s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#studio {
  width: 77px;
  top: calc(50% - 4px);
  left: -10px;
}

#studio-path {
  stroke-dasharray: 82;
  stroke-dashoffset: 82;
  animation: squiggle-circle 0.15s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  animation-delay: 5.1s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#group {
  width: 74px;
  top: calc(50% - 2px);
}

#group-path {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: squiggle-circle 0.1s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  animation-delay: 5.8s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#squiggle-circle {
  width: 186px;
}

#squiggle-circle-path {
  stroke-dasharray: 386 388;
  stroke-dashoffset: 387;
  animation: squiggle-circle 1.2s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  animation-delay: 6.5s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

@keyframes show-intro {
  0% {
    left: -50vw;
    opacity: 0;
  }

  100% {
    left: 0;
    opacity: 1;
  }
}

#intro {
  min-height: 650px;
  width: 100%;
  //background-color: #101818;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  animation: show-intro 0.75s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  padding-bottom: 100px;
}

#intro.set {
  left: 0;
  line-height: inherit;
}

#intro .text,
#description .text,
footer .text {
  width: 821px;
  max-width: 90vw;
  margin: 0 auto;
  padding: 80px 0 0;
}

#intro-container {
  transition: all 4.5s ease;
  background-color: var(--dynamic-background);
}

#description .text {
  position: relative;
  padding: 150px 0;
}

#intro .text .lower {
  position: relative;
}

#intro .text hr,
#description .text hr {
  width: 0;
  background: #fff;
  position: absolute;
  top: 6px;
  left: 0;
  border: none;
  margin: 0;
  transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  -webkit-transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  height: 1px;
}

#description .text hr {
  position: relative;
  height: 8px;
  width: 27px;
  background: #58585a;
  margin-bottom: 55px;
}

#intro .text hr.hr-slide-in {
  width: 70px;
}

#intro .text h3 {
  font-family: $bold-font-stack;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  -webkit-transition: all 1.25s cubic-bezier(0.08, 0.705, 0.005, 0.99);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  color: #fff;
  margin-left: 80px;
  text-transform: uppercase;
  padding-left: 0;

  &::before {
    display: none;
  }
}

#intro .squiggles {
  margin: 30px 0;
}

#intro .text p,
#description .text p {
  color: #fff;
  font-family: $body-font-stack;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1.6;

  @media only screen and (max-width: 767px) {
    letter-spacing: 0.1em;
  }
}

#intro .text p a {
  text-decoration: underline;
  color: #fff;
}

#description {
}

#description .text p {
  color: #000;
  font-family: $body-font-stack;
  font-weight: 100;
  font-size: 18px;
  line-height: inherit;
  line-height: 1.6;
}

#intro .date {
  position: absolute;
  bottom: 40px;
  left: 28px;
}

#intro #month {
  font-size: 14px;
  font-family: $body-font-stack;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  display: inline-block;
  color: #fff;
}

#intro #year_day {
  font-size: 14px;
  font-family: $body-font-stack;
  letter-spacing: 0.1em;
  line-height: 1.6em;
  margin-top: 60px;
  display: inline-block;
  color: #fff;
}

.accordion {
  width: 100%;
  height: 835px;
  position: relative;
}

@keyframes accordion-up {
  100% {
    top: 0;
    opacity: 1;
  }
}

.accordion ul {
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 40%;
  animation: accordion-up 1s cubic-bezier(0.08, 0.705, 0.005, 0.99) 0ms forwards;
  animation-delay: 1s;
}

.accordion ul li {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  width: 25vw;
  height: 835px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 500ms ease;
  background-size: cover;
}

.accordion ul li div {
  display: block;
  overflow: hidden;
  width: 100%;
}

.accordion ul li div.content {
  padding: 40px;
  box-sizing: border-box;
  transition: all 200ms ease;
  z-index: 3;
  position: relative;
  width: 470px;
  height: 300px;
}

.accordion ul li div.content .number {
  color: #000;
  font-size: 12px;
  font-size: $body-font-stack;
  font-weight: 500;
  margin-bottom: 30px;
}

.accordion ul li div.content {
  opacity: 0;
  margin: 0;
  width: 470px;
  height: 300px;
  text-overflow: ellipsis;
  position: relative;
  z-index: 5;
  overflow: hidden;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  opacity: 0;
  left: 250px;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  // pointer-events:none;
  animation-delay: 0.5s;
}

.accordion ul li div.content h2 {
  font-size: $body-font-stack;
  font-weight: 500;
  text-overflow: clip;
  font-size: 24px;
  margin-bottom: 18px;
}

.accordion ul li div.content .divider {
  width: 123px;
  height: 1px;
  background: #5abcbd;
  margin-bottom: 20px;
  border: none;
}

.accordion ul li div.content p {
  font-size: $body-font-stack;
  font-weight: normal;
  font-size: 16px;
  width: 100%;
}

.accordion ul li div.content .go {
  position: absolute;
  bottom: 0;
  left: 40px;
}

// .accordion ul li.open{
// 	width:60%;
// }
//
// .accordion ul li{
// 	width: 80px;
// }

@media screen and (max-width: 600px) {
  .accordion {
    height: auto;
    display: none;
  }

  .accordion ul li,
  .accordion ul li:hover,
  .accordion ul:hover li,
  .accordion ul:hover li:hover {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }
}

@media (hover: hover) {
  .accordion {
    position: relative;
  }

  .accordion .featured-text {
    font-size: 48px;
    font-family: $bold-font-stack;
    color: #fff;
    position: absolute;
    top: 100px;
    height: calc(100% - 100px);
    width: 100vw;
    pointer-events: none;
    font-weight: 600;
  }

  .accordion .featured-text .inner {
    width: 782px;
    max-width: 90vw;
    margin: 0 auto;
    line-height: 1.2em;
  }

  .accordion ul:hover li {
    width: 4vw;
  }

  .accordion ul:hover li:hover {
    width: 88vw;
  }

  .accordion ul:hover li:hover .content {
    width: 470px;
    height: 300px;
    max-width: 50vw;
    position: absolute;
    bottom: 0;
    left: 350px;
    background: #fff;
  }

  .accordion ul:hover li:hover .content.c0 {
    left: calc(350px + 4vw);
  }

  .accordion ul:hover li:hover .content.c1 {
    left: calc(350px + 4vw);
  }

  .accordion ul:hover li:hover .content.c2 {
    left: calc(350px - 4vw);
  }

  .accordion ul:hover li:hover .content.c3 {
    left: calc(350px - 8vw);
  }

  .accordion ul:hover li:hover .content {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

hr.thick {
  position: relative;
  height: 8px;
  width: 27px;
  background: #58585a;
  margin-bottom: 55px;
  margin-inline-start: 0;
}
