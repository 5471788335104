@import "design-tokens.scss";
@import "utilities.scss";
@import "animation.scss";

@font-face {
	font-family: 'SF UI Text';
	src: url("/fonts/SFUIText-Medium.woff2") format('woff2'), url("/fonts/SFUIText-Medium.woff") format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'SF UI Display';
	src: url("/fonts/SFUIDisplay-Thin.woff2") format('woff2'), url("/fonts/SFUIDisplay-Thin.woff") format('woff');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'SF UI Text';
	src: url("/fonts/SFUIText-Light.woff2") format('woff2'), url("/fonts/SFUIText-Light.woff") format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'SF UI Display';
	src: url("/fonts/SFUIDisplay-Medium.woff2") format('woff2'), url("/fonts/SFUIDisplay-Medium.woff") format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'SF UI Display';
	src: url("/fonts/SFUIDisplay-Light.woff2") format('woff2'), url("/fonts/SFUIDisplay-Light.woff") format('woff');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'SF UI Display';
	src: url("/fonts/SFUIDisplay-Regular.woff2") format('woff2'), url("/fonts/SFUIDisplay-Regular.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}
$bold-font-stack: 'SF UI Display', sans-serif;
$alternate-font-stack: 'SF UI Display',sans-serif;
$body-font-stack: 'SF UI Text',sans-serif;

html,
body {
  padding: 0;
  margin: 0;
  font-family: $body-font-stack;
  line-height: 1;
	letter-spacing: 0.02em;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

main {
  min-height: calc(100vh - 107px);
}

h1{
	font-size: 42px;
	margin-bottom: 30px;
	color: #010101;
	font-weight: 100;
	padding-left: 80px;
	letter-spacing: .02em;
	font-family: var(--bold-font-stack);
}

p{
	font-family: var(--body-font-stack);
	letter-spacing: .02em;
	line-height: 1.6;
	max-width: 100%;
	margin: 0 0 1em;
	font-weight: 300;
	color: #0b0a0a;
	text-align: left;
	font-size: 16px;
}

h3 {
	font-size: 12px;
	margin-bottom: 20px;
	color: #010101;
	font-weight: 500;
	padding-left: 80px;
	position: relative;
}

h3::before {
	content: ' ';
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	width: 70px;
	height: 1px;
	background: #000;
}

.font-regular{
	font-weight: 500  !important;
}

.font-light{
	font-weight: 100;
}

.description{
	font-family: var(--body-font-stack);
	letter-spacing: .02em;
	line-height: 1.8;
	max-width: 100%;
	font-weight: 300;
	color: #0b0a0a;
	text-align: left;
	font-size: 16px;
}

img{
	max-width: 100%
}

@import 'home';

.strike{
	position: relative;
	z-index: inherit;
}

a:hover{
	transition-delay: 0.25s;
	.strike:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #5abcbd;
    animation-name: strike;
    animation-duration: .75s;
    animation-timing-function: cubic-bezier(0.08,.705,.005,.99);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
	}
}