:root {
  --background-color: #fff;
  --color: #000;

  --footer-background: #000;
  --footer-color: #fff;

  --grid-gap: 32px;

  --bold-font-stack: 'SF UI Display', sans-serif;
  --alternate-font-stack: 'SF UI Display',sans-serif;
  --body-font-stack: 'SF UI Text',sans-serif;

  --dynamic-background: #101818;
}
