@use 'sass:map';
@use 'variants' as * with (
  $breakpoints: (
    'small': 640px,
    'medium': 768px,
    'large': 1024px,
    'wide': 1280px,
  )
);

// ASCII Art Generator
// http://www.patorjk.com/software/taag/#p=display&v=0&f=ANSI%20Shadow&t=variant

// ██████╗  █████╗ ███╗   ██╗ █████╗ ███╗   ██╗ █████╗
// ██╔══██╗██╔══██╗████╗  ██║██╔══██╗████╗  ██║██╔══██╗
// ██████╔╝███████║██╔██╗ ██║███████║██╔██╗ ██║███████║
// ██╔══██╗██╔══██║██║╚██╗██║██╔══██║██║╚██╗██║██╔══██║
// ██████╔╝██║  ██║██║ ╚████║██║  ██║██║ ╚████║██║  ██║
// ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝

.banana {
  @include variants("responsive" "hover" "group-hover") {
    color: #ffe135;
  }
}

// ████████╗███████╗██╗  ██╗████████╗
// ╚══██╔══╝██╔════╝╚██╗██╔╝╚══██╔══╝
//    ██║   █████╗   ╚███╔╝    ██║
//    ██║   ██╔══╝   ██╔██╗    ██║
//    ██║   ███████╗██╔╝ ██╗   ██║
//    ╚═╝   ╚══════╝╚═╝  ╚═╝   ╚═╝

$text: (
  "left": left,
  "right": right,
  "center": center,
  "justify": justify,
);

.text {
  @include options($text, "responsive") using ($value) {
    text-align: $value;
  }
}

// ███████╗██████╗  █████╗  ██████╗██╗███╗   ██╗ ██████╗
// ██╔════╝██╔══██╗██╔══██╗██╔════╝██║████╗  ██║██╔════╝
// ███████╗██████╔╝███████║██║     ██║██╔██╗ ██║██║  ███╗
// ╚════██║██╔═══╝ ██╔══██║██║     ██║██║╚██╗██║██║   ██║
// ███████║██║     ██║  ██║╚██████╗██║██║ ╚████║╚██████╔╝
// ╚══════╝╚═╝     ╚═╝  ╚═╝ ╚═════╝╚═╝╚═╝  ╚═══╝ ╚═════╝

$spacing: (
  "0": 0,
  "1": 4px,
  "2": 8px,
  "3": 12px,
  "4": 16px,
  "5": 20px,
  "6": 24px,
  "8": 28px,
  "9": 32px,
  "10": 36px,
  "11": 40px,
  "12": 44px,
  "13": 48px,
  "14": 52px,
  "15": 56px,
  "16": 60px,
  "17": 64px,
  "18": 68px,
  "19": 72px,
  "20": 76px,
  "21": 80px,
);

// Add auto to the positive margin classes
$margin: map.merge(
  (
    "auto": auto,
  ),
  $spacing
);

@include variants("responsive") using ($props...) {
  // ██████╗  █████╗ ██████╗ ██████╗ ██╗███╗   ██╗ ██████╗
  // ██╔══██╗██╔══██╗██╔══██╗██╔══██╗██║████╗  ██║██╔════╝
  // ██████╔╝███████║██║  ██║██║  ██║██║██╔██╗ ██║██║  ███╗
  // ██╔═══╝ ██╔══██║██║  ██║██║  ██║██║██║╚██╗██║██║   ██║
  // ██║     ██║  ██║██████╔╝██████╔╝██║██║ ╚████║╚██████╔╝
  // ╚═╝     ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝ ╚═════╝

  .p {
    @include options($spacing, $props...) using ($value) {
      padding: $value;
    }
  }

  .px {
    @include options($spacing, $props...) using ($value) {
      padding-right: $value;
      padding-left: $value;
    }
  }

  .py {
    @include options($spacing, $props...) using ($value) {
      padding-bottom: $value;
      padding-top: $value;
    }
  }

  .pt {
    @include options($spacing, $props...) using ($value) {
      padding-top: $value;
    }
  }

  .pr {
    @include options($spacing, $props...) using ($value) {
      padding-right: $value;
    }
  }

  .pb {
    @include options($spacing, $props...) using ($value) {
      padding-bottom: $value;
    }
  }

  .pl {
    @include options($spacing, $props...) using ($value) {
      padding-left: $value;
    }
  }

  // ███╗   ███╗ █████╗ ██████╗  ██████╗ ██╗███╗   ██╗
  // ████╗ ████║██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║
  // ██╔████╔██║███████║██████╔╝██║  ███╗██║██╔██╗ ██║
  // ██║╚██╔╝██║██╔══██║██╔══██╗██║   ██║██║██║╚██╗██║
  // ██║ ╚═╝ ██║██║  ██║██║  ██║╚██████╔╝██║██║ ╚████║
  // ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝

  .m {
    @include options($margin, $props...) using ($value) {
      margin: $value;
    }
  }

  .mx {
    @include options($margin, $props...) using ($value) {
      margin-right: $value;
      margin-left: $value;
    }
  }

  .my {
    @include options($margin, $props...) using ($value) {
      margin-bottom: $value;
      margin-top: $value;
    }
  }

  .mt {
    @include options($margin, $props...) using ($value) {
      margin-top: $value;
    }
  }

  .mr {
    @include options($margin, $props...) using ($value) {
      margin-right: $value;
    }
  }

  .mb {
    @include options($margin, $props...) using ($value) {
      margin-bottom: $value;
    }
  }

  .-ml {
    @include options($margin, $props...) using ($value) {
      margin-left: $value;
    }
  }

  //       ███╗   ███╗ █████╗ ██████╗  ██████╗ ██╗███╗   ██╗
  //       ████╗ ████║██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║
  // █████╗██╔████╔██║███████║██████╔╝██║  ███╗██║██╔██╗ ██║
  // ╚════╝██║╚██╔╝██║██╔══██║██╔══██╗██║   ██║██║██║╚██╗██║
  //       ██║ ╚═╝ ██║██║  ██║██║  ██║╚██████╔╝██║██║ ╚████║
  //       ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝

  .-m {
    @include options($spacing, $props...) using ($value) {
      margin: -$value;
    }
  }

  .-mx {
    @include options($spacing, $props...) using ($value) {
      margin-right: -$value;
      margin-left: -$value;
    }
  }

  .-my {
    @include options($spacing, $props...) using ($value) {
      margin-bottom: -$value;
      margin-top: -$value;
    }
  }

  .-mt {
    @include options($spacing, $props...) using ($value) {
      margin-top: -$value;
    }
  }

  .-mr {
    @include options($spacing, $props...) using ($value) {
      margin-right: -$value;
    }
  }

  .-mb {
    @include options($spacing, $props...) using ($value) {
      margin-bottom: -$value;
    }
  }

  .-ml {
    @include options($spacing, $props...) using ($value) {
      margin-left: -$value;
    }
  }
}

// ██╗      █████╗ ██╗   ██╗ ██████╗ ██╗   ██╗████████╗
// ██║     ██╔══██╗╚██╗ ██╔╝██╔═══██╗██║   ██║╚══██╔══╝
// ██║     ███████║ ╚████╔╝ ██║   ██║██║   ██║   ██║
// ██║     ██╔══██║  ╚██╔╝  ██║   ██║██║   ██║   ██║
// ███████╗██║  ██║   ██║   ╚██████╔╝╚██████╔╝   ██║
// ╚══════╝╚═╝  ╚═╝   ╚═╝    ╚═════╝  ╚═════╝    ╚═╝

$display: (
  "block": block,
  "inline-block": inline-block,
  "inline": inline,
  "flex": flex,
  "inline-flex": inline-flex,
  "grid": grid,
  "inline-grid": inline-grid,
  "hidden": none,
) !default;

@include options($display, "responsive") using ($value) {
  display: $value;
}
